import React from "react";
import styled from "styled-components";

import { SectionTitle } from "../../common/SectionTitle";
import KaranCartoon from "../../assets/KaranCartoon.png";

import Grid from "@mui/material/Grid";

import Rotate from "react-reveal/Rotate";
import Flip from "react-reveal/Flip";

export const About = ({ title }) => {
  return (
    <>
      <SectionTitle title="About" />
      <Center>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <Rotate bottom left>
              <div>
                <strong>
                  <p>
                    As a web developer, I am passionate about creating high
                    quality, clean code that meets the needs of users and
                    clients. I am always seeking new challenges and
                    opportunities to improve my skills and stay up-to-date with
                    the latest technologies and best practices in the field.
                  </p>
                </strong>
                <p>
                  Although I have a degree in Pre Health, I discovered my true
                  passion for web development a few years ago and have been
                  dedicated to learning and growing in this field ever since.
                  Through self-study and the development of various projects, I
                  have honed valuable skills such as persistence, time
                  management, and resourcefulness.
                </p>
                <p>
                  I am now seeking industry experience and the chance to work in
                  a team environment to further develop my skills and knowledge.
                  I am open to new connections, ideas, and opportunities, and
                  would love to hear from potential employers or collaborators.
                  Please don't hesitate to contact me with any questions or
                  inquiries.
                </p>
              </div>
            </Rotate>
          </Grid>
          <Grid className="about-container" item md={5}>
            <Flip top>
              <div className="about-img">
                <img src={KaranCartoon} />
              </div>
            </Flip>
          </Grid>
        </Grid>
      </Center>
    </>
  );
};

const Center = styled.div`
  margin: auto;
  max-width: 1000px;

  .about-img {
    max-width: 400px;
  }

  .about-container {
    margin: auto;
  }

  img {
    border-radius: 50%;
    background-color: #3cb681;
    width: 100%;
  }
`;

{
  /* <div>
<strong>
  <p>
    Software development isn't just a job for me. It's offered
    an engaging challenge to continually learn and improve my
    skills in creating high quality clean code. Constantly
    studying design patterns also how to make code reusable.
  </p>
</strong>
<p>
  Graduate of Pre Health but found my college major differs from
  my current pursuits. It’s not been long since I started
  studying web development, merely two-three years ago, and ever
  since, I’ve been building randomized projects. Practice and
  experience has made me a great developer.
</p>
<p>
  I am now looking for industry experience, to learn in a team
  environment and solidify what I’ve learned. Being a
  self-taught developer has put through a great journey because
  it's taught me persistence, time-management, dedication,
  resourcefulness, and motivation to go down the self-taught
  path.
</p>
<p>
  I'm always excited to make new connections, hear about new
  ideas, and explore potential opportunities, so please feel
  free to contact me or drop down your queries.
</p>
</div> */
}

{
  /* <div>
  <strong>
    <p>
      Software development is more than just a job for me. It's an engaging
      challenge that allows me to constantly learn and improve my skills in
      creating high quality, clean code. I am always studying design patterns
      and ways to make my code reusable.
    </p>
  </strong>
  <p>
    Although I graduated with a degree in Pre Health, my interests have led me
    down a different path. I've been studying web development for the past two
    to three years, and in that time I've built a variety of projects to
    practice my skills. This self-taught approach has taught me valuable skills
    such as persistence, time management, dedication, resourcefulness, and
    motivation.
  </p>
  <p>
    I am now looking for industry experience and the opportunity to learn in a
    team environment. I believe this will help me solidify my knowledge and grow
    as a developer. I'm always open to making new connections, hearing about new
    ideas, and exploring potential opportunities. Feel free to contact me with
    any questions or inquiries.
  </p>
</div>; */
}
