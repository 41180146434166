import React from "react";
import styled from "styled-components";

/* Images */
import patternBg from "../../assets/patternBg.png";
import homeblue from "../../assets/homeblue.PNG";
import greyfalls from "../../assets/greyfalls.PNG";

/* libaries */
import Typed from "react-typed";
import Flippy, { FrontSide, BackSide } from "react-flippy";

/* components */
import Button from "@mui/material/Button";
import UseAnimations from "react-useanimations";
import github from "react-useanimations/lib/github";
import linkedin from "react-useanimations/lib/linkedin";

import Fade from "react-reveal/Fade";

export const Hero = () => {
  return (
    <Wrapper>
      {/* <img className="hero-bg" src={patternBg} /> */}
      <Content>
        <div className="content-contain">
          <div className="hero-1">
            <h1 className="karan">Hi, I'm Karan Singh.</h1>

            <Typed
              className="typed"
              strings={["MERN Stack Developer"]}
              typeSpeed={100}
              backDelay={15000}
              backSpeed={30}
              loop
            />

            <div className="home-buttons">
              <Button
                className="btncolor"
                href="#projects"
                size="medium"
                variant="contained"
              >
                VIEW MY WORK
              </Button>
              <Button
                className="btncolor"
                href="#contact"
                size="medium"
                variant="contained"
              >
                GET IN TOUCH
              </Button>
            </div>
            <Fade left cascade>
              <div>
                <p>
                  I have the skills and knowledge to develop efficient and
                  effective solutions for a wide range of clients. I want to
                  challenge myslef to grow as a developer and make a positive
                  impact in the field. I am confident that my skills and
                  dedication would make me a valuable asset to any team.
                </p>
                {/* <p>
                  Passionate about Web Design & Development. I inspire making
                  websites that clearly communicate with visitors and
                  interactive to make it enjoyable. If you are seeking a driven
                  and skilled web developer, please don't hesitate to contact me
                  for more information.
                </p> */}
              </div>
            </Fade>
            <div className="hero-animation">
              <a
                href="https://github.com/karan353?tab=repositories"
                target="_blank"
                className="home2-icons"
              >
                <UseAnimations animation={github} size={36} />
              </a>
              <a
                href="https://www.linkedin.com/in/karan-singh-267472203"
                target="_blank"
                className="home2-icons"
              >
                <UseAnimations animation={linkedin} size={36} />
              </a>
            </div>
          </div>

          <div className="hero-2">
            <Flippy
              flipOnClick={true}
              flipDirection="horizontal"
              style={{ width: "100%", height: "auto", cursor: "pointer" }}
            >
              <FrontSide
                style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
              >
                <img className="me" src={homeblue} />
              </FrontSide>
              <BackSide
                style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
              >
                <img className="me" src={greyfalls} />
              </BackSide>
            </Flippy>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 95vh;

  width: 100%;
  background-image: radial-gradient(
      ellipse at bottom,
      #3cb681 5%,
      transparent 60%
    ),
    linear-gradient(136deg, transparent, #63eebf 290%),
    linear-gradient(115deg, #27debe, transparent 40%),
    linear-gradient(180deg, transparent 0, #14966a 70%),
    radial-gradient(ellipse at -70% -180%, transparent 80%, #c5e9da 0),
    radial-gradient(ellipse at bottom, #14966a 40%, transparent 80%),
    radial-gradient(ellipse at 5% 340%, transparent 80%, #bbedb5 0);

  @media (min-width: 984px) {
    max-height: 95vh;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;

  .content-contain {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    padding-top: 2rem;
  }

  .hero-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 350px;
    margin: 1rem 0.4rem;
  }

  .hero-2 {
    max-width: 350px;
    margin: 1rem 0.4rem;
  }

  .karan {
    margin: 0;
    padding: 0;
    font-size: 36px;
    font-weight: 700;
  }

  .typed {
  }

  .me {
    width: 100%;
    border-radius: 8px;
  }

  .home-buttons {
    display: flex;
    gap: 8px;
  }

  .hero-animation {
    display: flex;
    gap: 8px;
  }
`;

{
  /* <div>
<p>
  I am from Toronto, Ontario and currently living in Niagara
  Falls. I enjoy building projects that are meaningful and are
  beautiful user experiences.
</p>
<p>
  Passionate about Web Design & Development. I inspire making
  websites that clearly communicate with visitors and
  interactive to make it enjoyable.
</p>
</div> */
}
