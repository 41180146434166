import React from "react";
import styled from "styled-components";
import { ProjectCard } from "../../common/ProjectCard";

export const Item1 = ({ image }) => {
  return (
    <>
      <Wrapper>
        <h4>Landing Page</h4>
        <Projects>
          <div>
            <ProjectCard
              title="Airbnb"
              image="https://digital.hbs.edu/platform-digit/wp-content/uploads/sites/2/2019/10/airbnb-678x381.png"
              link="https://startling-longma-11ecc4.netlify.app/"
              code="https://github.com/karan353/airbnbClone"
            />
          </div>
          <div>
            <ProjectCard
              title="Shopify"
              image="https://cdn.shopify.com/s/files/1/0533/2089/files/shopify-online-store.jpg?v=1624557750"
              link="https://glittering-lamington-f230dd.netlify.app/"
              code="https://github.com/karan353/shopify-clone"
            />
          </div>
          <div>
            <ProjectCard
              title="Doordash"
              image="https://techcrunch.com/wp-content/uploads/2021/11/doordash-header.jpeg"
              link="https://willowy-shortbread-2f73ba.netlify.app/"
              code="https://github.com/karan353/doordash-clone"
            />
          </div>
        </Projects>
      </Wrapper>
      <Wrapper>
        <h4>Websites</h4>
        <Projects>
          <div>
            <ProjectCard
              title="Medical"
              image="https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              link="https://medical-bay.vercel.app/"
              code="https://github.com/karan353/Medical"
            />
          </div>
          <div>
            <ProjectCard
              title="Quiz App"
              image="https://images.pexels.com/photos/6684265/pexels-photo-6684265.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              link="https://flourishing-macaron-e62f8d.netlify.app/"
              code="https://github.com/karan353/Quizz-App"
            />
          </div>
          <div>
            {/* <ProjectCard
              title="Learn Punjabi"
              image="https://images.unsplash.com/photo-1594046185125-dc38e9abf8b0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2671&q=80"
              link="https://www.learnpunjabi.info/"
              code="https://github.com/karan353/learnpunjabi"
            /> */}
          </div>
        </Projects>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  display: flex;

  h4 {
    width: 350px;
  }

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    h4 {
      width: 100%;
    }
  }
`;

const Projects = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
  width: 100%;

  @media (max-width: 760px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
