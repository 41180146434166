import React from "react";
import styled from "styled-components";

export const ProjectCard = ({ image, title, link, code }) => {
  return (
    <Container>
      <h5>{title}</h5>
      <a className="image" href={link} target="_blank">
        <img src={image} />
      </a>
      <div>
        <a href={code} target="_blank">
          Code
        </a>
        <a href={link} target="_blank">
          Live Demo
        </a>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;

  h5 {
    margin: 4px 0;
  }

  :hover {
    background: #fafafa;
    --tw-bg-opacity: 0.6;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    border-radius: 0.5rem;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }

  a:hover {
    color: dodgerblue;
  }

  img {
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }

  .image {
    height: 167px;
    max-width: 300px;
  }
`;
