import * as React from "react";
import styled from "styled-components";

import { Courses } from "../Courses/Courses";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SectionTitle } from "../../common/SectionTitle";

// effects from react-reveal
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

// icons
import { MdOutlineEmail } from "react-icons/md";
import { RiGithubLine } from "react-icons/ri";
import { FiLinkedin } from "react-icons/fi";

import Button from "@mui/material/Button";
import ContactSmaller from "../Contact/ContactSmaller";
import { Courses2 } from "../Courses/Courses2";

export default function Education() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <SmallerSpace />
      <SectionTitle title="Education" />
      <Wrapper>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Css</Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {/* I am an accordion */}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li>Anatomy of a CSS rule</li>
                <li>Browser support</li>
                <li>
                  How CSS works in the browser
                  <ul>
                    <li>The Document Object Model (DOM)</li>
                    <li>The CSS Object Model (CSSOM)</li>
                  </ul>
                </li>
                <li>CSS Selectors</li>
                <li>
                  Basic CSS Concepts
                  <ul>
                    <li>The box model</li>
                    <li>Box sizing</li>
                    <li>Block and inline elements</li>
                    <li>Overflow</li>
                  </ul>
                </li>
                <li>Styling</li>
                <li>
                  Layout and Positioning
                  <ul>
                    <li>Flexbox</li>
                    <li>CSS Grid</li>
                  </ul>
                </li>
                <li>Responsive Design</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Javascript
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {/* You are currently not an owner */}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li>Control Flow</li>
                <li>Objects (Factory and constructor)</li>
                <li>Arrays</li>
                <li>Functions</li>
                <li>Call Stack</li>
                <li>Execution Context</li>
                <li>Object-oriented programming</li>
                <li>Prototypes and prototypical inheritance</li>
                <li>Prototypical Chain</li>
                <li>ES6</li>
                <li>DOM Manipulation</li>
                <li>Events</li>
                <li>Asynchronous</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>React</Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {/* Filtering has been entirely disabled for whole web server */}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li>Components</li>
                <li>Pagination, Filtering, and Sorting</li>
                <li>Routing</li>
                <li>Forms</li>
                <li>Calling Backend Services</li>
                <li>Deployment</li>
                <li>Hooks</li>
                <li>Higher Order Component</li>
                <li>Props vs State</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Node</Typography>
            <Typography sx={{ color: "text.secondary" }}>
              {/* Filtering has been entirely disabled for whole web server */}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <ul>
                <li>Node Package Manager (NPM)</li>
                <li>Node Module System</li>
                <li>Asynchronous</li>
                <li>RESTful API's</li>
                <li>
                  Express
                  <ul>
                    <li>CRUD</li>
                    <li>Middleware</li>
                    <li>Debugging</li>
                    <li>Authentication and Authorization</li>
                    <li>Handling and Logging Errors</li>
                    <li>Async Errors</li>
                  </ul>
                </li>
                <li>
                  Mongoose
                  <ul>
                    <li>Types</li>
                    <li>Schemas</li>
                    <li>Models</li>
                    <li>Data Validation</li>
                    <li>Async Validators</li>
                    <li>Validation Errors</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Personal data
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion> */}
        {/* <Courses /> */}
        <Courses2 />
        <SmallerSpace />

        {/* contact */}
        <Space>
          <h1 id="contact">Ask me any questions you might have.</h1>
          <Contact>
            <Button
              href="https://www.linkedin.com/in/karan-singh-267472203"
              target="_blank"
              variant="outlined"
            >
              <FiLinkedin className="react-icons-contact" />
              Linkedln
            </Button>
            <Button
              href="https://github.com/karan353?tab=repositories"
              target="_blank"
              variant="outlined"
            >
              <RiGithubLine className="react-icons-contact" />
              GitHub
            </Button>

            <Button variant="outlined">
              <MdOutlineEmail
                href="mailto: karan.singh1269@gmail.com"
                target="_blank"
                className="react-icons-contact"
              />
              <a href="mailto:karan.singh1269@gmail.com" target="_blank">
                Email
              </a>
            </Button>
          </Contact>
        </Space>
        <ContactSmaller />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  max-width: 1000px;

  margin: auto;
  h1 {
    font-size: 2rem;
    text-align: center;
  }
`;

const Space = styled.section`
  height: 290px;

  @media (max-width: 984px) {
    max-height: 100px;
  }
`;

const Contact = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`;

const SmallerSpace = styled.section`
  height: 100px;
`;
