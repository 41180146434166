import React from "react";
import styled from "styled-components";

import { SectionTitle } from "../../common/SectionTitle";

const Seesight = () => {
  return (
    <Wrapper>
      <SectionTitle title="Experience" />
      Intern at SeeSight Tours in Niagara Falls. July - October 2022.
    </Wrapper>
  );
};

export default Seesight;

const Wrapper = styled.section`
  text-align: center;
`;
