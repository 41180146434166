import React from "react";
import styled from "styled-components";

import { SectionTitle } from "../../common/SectionTitle";

// Material Ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

// Mui Icons
import GitHubIcon from "@mui/icons-material/GitHub";

// images and icons
import html5 from "../../assets/languages/html5.png";
import css from "../../assets/languages/css.svg";
import javascript from "../../assets/languages/javascript.svg";
import ReactIcon from "../../assets/languages/ReactIcon.png";
import nodeLogo from "../../assets/languages/nodeLogo.png";
import expressLogo from "../../assets/languages/expressLogo.png";
import mongodbLogo from "../../assets/languages/mongodbLogo.png";
import sassLogo from "../../assets/languages/sassLogo.png";
import Redux from "../../assets/languages/Redux.png";
import NextJs from "../../assets/languages/NextJs.png";
import TypeScript from "../../assets/languages/TypeScript.png";

/* lottie */
import Lottie from "react-lottie";
import tech from "../../assets/Lottie/tech";

/* animation */
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

/* lottie settings */
const defaultOptions = {
  loop: true,
  autoplay: true,
  controls: false,
  animationData: tech,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const Skills2 = () => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <SectionTitle title="Skills" />

      <Container>
        <ListBox>
          <LanguageList>
            {/* Col One */}

            <List
              sx={{
                width: {
                  xs: 90,
                  sm: 150,
                  md: 200,
                },
                bgcolor: "#f5f5f5",
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
              component="nav"
            >
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={html5} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Html"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "95%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="95%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={css} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Css"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#03c1a2",
                      width: "90%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="90%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={javascript} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="JavaScript"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List className="1" component="div" disablePadding>
                  <ListItemButton
                    className="2"
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "80%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText className="4" primary="80%" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            {/* Col Two */}

            <List
              sx={{
                width: {
                  xs: 90,
                  sm: 150,
                  md: 200,
                },
                bgcolor: "#f5f5f5",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
              component="nav"
            >
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={ReactIcon} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="React"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "75%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="75%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <GitHubIcon />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Git"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#03c1a2",
                      width: "50%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="50%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={nodeLogo} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Node"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List className="1" component="div" disablePadding>
                  <ListItemButton
                    className="2"
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "70%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText className="4" primary="70%" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            {/* Col Three */}

            <List
              sx={{
                // width: "100%",
                // maxWidth: 250,
                width: {
                  xs: 90,
                  sm: 150,
                  md: 200,
                },
                bgcolor: "#f5f5f5",
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
              component="nav"
            >
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img
                    src={expressLogo}
                    style={{ height: "20px", borderRadius: "4px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Express"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "80%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="80%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={mongodbLogo} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="MongoDb"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 4,
                      bgcolor: "#03c1a2",
                      width: "70%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText primary="70%" />
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <img src={sassLogo} style={{ height: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ["@media only screen and (max-width: 600px)"]: {
                      display: "none",
                    },
                  }}
                  primary="Sass"
                />
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List className="1" component="div" disablePadding>
                  <ListItemButton
                    className="2"
                    sx={{
                      pl: 4,
                      bgcolor: "#027d7c",
                      width: "70%",
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }}
                  >
                    <ListItemText className="4" primary="70%" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </LanguageList>
        </ListBox>
        <ResponsiveBox>
          <ResponseImage>
            <div className="lottie">
              <Lottie
                speed={0.5}
                options={defaultOptions}
                height={"auto"}
                width={"100%"}
                controls={false}
                isClickToPauseDisabled
              />
            </div>
            <h4>Responsive Design</h4>
          </ResponseImage>
        </ResponsiveBox>
      </Container>
      <MoreBox>
        <div className="working_with">
          <h4>I'm currently working with</h4>
          <div>
            <img className="redux" src={Redux} />
            <img className="nextjs" src={NextJs} />
            <img className="typescript" src={TypeScript} />
          </div>
        </div>
        <div className="other_skills">
          <p>Getting jobs done on time.</p>
          <p>Strong communication skills.</p>
          <p>Able to focus on priorities.</p>
          <p>The ability to learn new methods and technologies.</p>
        </div>
      </MoreBox>
    </>
  );
};

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
`;

const ListBox = styled.div`
  height: 100%;
  background: #f5f5f5;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
`;

const LanguageList = styled.div`
  display: flex;
  flex-direction: row wrap;
  width: 100%;
`;

const ResponsiveBox = styled.div`
  height: 308px;
`;

const ResponseImage = styled.div`
  position: relative;
  width: 19.5rem;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);

  .lottie {
    padding: 2rem;
  }

  h4 {
    position: absolute;
    bottom: 25px;
    right: 9.8rem;
    margin: 0;
  }
`;

const MoreBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  margin: 20px auto 60px auto;
  justify-content: center;
  max-width: 700px;
  background: #f5f5f5;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);

  .working_with {
    padding: 1rem;
  }
  .other_skills {
    padding: 1rem;
  }

  .redux {
    height: 50px;
    width: auto;
  }
  .nextjs {
    height: 60px;
    width: auto;
    margin: 0 8px;
  }
  .typescript {
    height: 50px;
    width: auto;
  }
`;

// ["@media (min-width:780px)"]: {
//   width: "80%",
// },
