import React from "react";
import styled from "styled-components";

export const Circle = () => {
  return <Container>Circle</Container>;
};

const Container = styled.div`
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 3px 3px -1px rgb(8 11 14 / 10%);
  border: 1px solid #f7f8f9;
  width: 2.5rem;
  border-radius: 1000rem;
  background: #ffffff;
  line-height: 24px;
  font-size: 14px;

  :hover {
    transform: translateY(-0.2rem);
    transition: 0.4s;
    box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 3px 3px -1px rgb(8 11 14 / 10%);
  }

  // @media (min-width: 720px) {
  //   margin-left: 3rem;
  //   margin-right: 3rem;
  // }
`;
