import React from "react";
import styled from "styled-components";

import { Circle } from "../../common/Circle";
import { SectionTitle } from "../../common/SectionTitle";

/* images */
import css from "../../assets/languages/css.svg";
import html5 from "../../assets/languages/html5.png";
import javascript from "../../assets/languages/javascript.svg";
import ReactIcon from "../../assets/languages/ReactIcon.png";
import Sass from "../../assets/languages/Sass.png";
import Redux from "../../assets/languages/Redux.png";
import NextJs from "../../assets/languages/NextJs.png";
import TypeScript from "../../assets/languages/TypeScript.png";
import github from "../../assets/languages/github.svg";

/* lottie */
import Lottie from "react-lottie";
import tech from "../../assets/Lottie/tech";

/* icons */
import { MdOutlineEmail } from "react-icons/md";
import { RiGithubLine } from "react-icons/ri";
import { FiLinkedin } from "react-icons/fi";

/* animation */
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

/* lottie settings */
const defaultOptions = {
  loop: true,
  autoplay: true,
  controls: false,
  animationData: tech,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const Skills1 = () => {
  return (
    <>
      {/* <Circle /> */}
      <SectionTitle title="Skills" />
      <Container>
        <Box>
          <h2>Responsive Design</h2>
          <Box1>
            <Lottie
              speed={0.5}
              options={defaultOptions}
              height={"auto"}
              width={"100%"}
              controls={false}
              isClickToPauseDisabled
            />
          </Box1>
        </Box>
        <Box>
          <Box2>
            <img src={html5} />
            <img src={css} />
            <img src={javascript} />
            <img src={ReactIcon} />
            <img src={Sass} />
            <img src={github} />
          </Box2>
          <h4>I'm currently working with</h4>
          <Box3>
            <img className="redux" src={Redux} />
            <img className="nextjs" src={NextJs} />
            <img className="typescript" src={TypeScript} />
          </Box3>
        </Box>
        <Box>
          <Box4>
            <p>Getting jobs done on time.</p>
            <p>Strong communication skills.</p>
            <p>Able to focus on priorities.</p>
            <p>The ability to learn new methods and technologies.</p>
          </Box4>
        </Box>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  min-height: 80vh;

  div {
    padding: 1em;
  }

  img {
    width: 5rem;
    height: 5rem;
    padding: 4px;
  }

  .learning {
    max-width: 4rem;
    height: auto;
  }
`;

const Box = styled.div`
  height: 434px;
`;
const Box1 = styled.div`
  dispaly: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 19.5rem;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
`;
const Box2 = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 61%;
  width: 19.5rem;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
`;
const Box3 = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
  width: 19.5rem;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);

  .redux {
    height: 50px;
    width: auto;
  }
  .nextjs {
    height: 60px;
    width: auto;
  }
  .typescript {
    height: 50px;
    width: auto;
  }
`;
const Box4 = styled.div`
  postion: relative;
  height: 97%;
  width: 19.5rem;
  padding: 2rem;
  border-radius: 12px;
  border: 1px solid #f7f8f9;
  box-shadow: 0 0 1px 0 rgb(8 11 14 / 6%), 0 16px 16px -1px rgb(8 11 14 / 10%);
`;
