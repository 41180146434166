import React from "react";
import styled from "styled-components";

import Flip from "react-reveal/Flip";

export const Courses2 = () => {
  return (
    <Wrapper>
      <h3 className="text">Courses and Certificates</h3>
      <Course>
        <Flip top>
          <Box>
            <img
              className="course-logo"
              src="https://www.udemy.com/staticx/udemy/images/v7/logo-udemy.svg"
            />

            <h5>Responsive Web Design with HTML5 and CSS3 - Advanced</h5>
            <h7>Paul Cheney, Web Designer and Developer</h7>

            <h5>The Web Developer Bootcamp 2022</h5>
            <h7>Colt Steele, Developer and Bootcamp Instructor</h7>

            <h5>JavaScript: Understanding the Weird Parts</h5>
            <h7>Anthony Alicea, Software Developer, Architect, and UX</h7>

            <h5>Build Web Apps with React & Firebase</h5>
            <h7>
              The Net Ninja (Shaun Pelling), Online Coding Tutor & Net Ninja
            </h7>
          </Box>
        </Flip>
        <Flip top>
          <Box>
            <img
              className="course-logo"
              src="https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=height:120/https://file-uploads.teachablecdn.com/be9f614a58674fe1a67044fb1158fff1/79b0bba8c4c441e5bbe715364cb9e770"
            />

            <h5>The Ultimate JavaScript Mastery Series - Part 1</h5>

            <h5>The Ultimate JavaScript Mastery Series - Part 2</h5>

            <h5>Mastering React</h5>

            <h5>The Ultimate HTML5 & CSS3 Series: Part 1</h5>

            <h5>The Ultimate HTML5 & CSS3 Series: Part 2</h5>
          </Box>
        </Flip>
        <Flip top>
          <Box>
            <h3>Books</h3>
            <img
              className="books"
              src="https://images-na.ssl-images-amazon.com/images/I/31b4K-hFH-L._SX395_BO1,204,203,200_.jpg"
            />
            <img
              className="books"
              src="https://images-na.ssl-images-amazon.com/images/I/31SRWF+LkKL._SX258_BO1,204,203,200_.jpg"
            />
            <img
              className="books"
              src="https://images-na.ssl-images-amazon.com/images/I/510JjoNTdOL._SX379_BO1,204,203,200_.jpg"
            />
            <img
              className="books"
              src="https://images-na.ssl-images-amazon.com/images/I/51InjRPaF7L._SX377_BO1,204,203,200_.jpg"
            />
          </Box>
        </Flip>
      </Course>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .course-logo {
    max-height: 100px;
    width: auto;
  }

  .books {
    max-height: 100px;
    width: auto;
  }
`;

const Course = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  width: 100%;
`;

const Box = styled.div`
  max-width: 280px;
  min-width: 200;
`;
