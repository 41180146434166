import React from "react";
import styled from "styled-components";

export const SectionTitle = ({ title }) => {
  return <Title>{title}</Title>;
};

const Title = styled.h2`
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 20px;
`;
