import React from "react";
import styled from "styled-components";
import { ProjectCard } from "../../common/ProjectCard";

export const Item2 = ({ image }) => {
  return (
    <Wrapper>
      <h4>Projects</h4>
      <Projects>
        <div>
          <ProjectCard
            title="WeatherApp"
            image="https://images.pexels.com/photos/209831/pexels-photo-209831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            link="https://next-weather-app-sandy.vercel.app/"
            code="https://github.com/karan353/nextWeatherApp"
          />
        </div>
        <div>
          <ProjectCard
            title="MovieApp"
            image="https://images.pexels.com/photos/5662857/pexels-photo-5662857.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            link="https://stunning-parfait-c550e0.netlify.app/"
            code="https://github.com/karan353/SearchMovie"
          />
        </div>
      </Projects>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;

  h4 {
    width: 350px;
  }

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;
    h4 {
      width: 100%;
    }
  }
`;

const Projects = styled.section`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1.5rem;
  width: 100%;

  @media (max-width: 760px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
