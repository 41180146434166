import "./App.css";
import styled from "styled-components";

import ProjectsNav from "./sections/Projects/ProjectsNav";
import { Hero } from "./sections/Hero/Hero";
import { About } from "./sections/About/About";
import Seesight from "./sections/Experience/Seesight";
import { Skills1 } from "./sections/Skills/Skills1";
import { Skills2 } from "./sections/Skills/Skills2";
import Education from "./sections/Education/Education";
import Contact from "./sections/Contact/Contact";
import { Footer } from "./sections/Footer/Footer";
import { Courses } from "./sections/Courses/Courses";
import { Courses2 } from "./sections/Courses/Courses2";

function App() {
  return (
    <>
      <Hero />
      <Wrapper>
        <div className="space">
          <About />
          {/* <Skills1 /> */}
          <Skills2 />
          <Seesight />
          <ProjectsNav />
          <Education />
          {/* <Courses2 />  */}
        </div>
      </Wrapper>
      <Contact />
      <Footer />
    </>
  );
}

export default App;

const Wrapper = styled.section`
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  // margin-top: 2rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: -40px;

  @media (min-width: 720px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  @media (max-width: 720px) {
    margin-top: 10px;
  }

  .space {
    padding: 1rem;
  }
`;
