import React, { useRef } from "react";
import styled from "styled-components";

import emailjs from "@emailjs/browser";

/* lottie animation */
import Lottie from "react-lottie";
// import * as mailAnimation from "../assets/Lottie/83358-contact-green";
import * as mail1 from "../../assets/Lottie/mail1";

/* ui animation */
import Zoom from "react-reveal/Zoom";

/* materail ui */
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";

/* lottie animation settings */
const defaultOptions = {
  loop: true,
  autoplay: true,
  controls: false,
  animationData: mail1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ContactSmaller = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_nwxdtli",
      "template_qjlmas7",
      form.current,
      "bkNznNlmrmH2U0Nvb"
    );

    e.target.reset();
  };
  return (
    <Wrapper className="close">
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Zoom left>
            <div className="mail-animation">
              <Lottie
                options={defaultOptions}
                height={"auto"}
                width={"100%"}
                controls={false}
                isClickToPauseDisabled
              />
            </div>
          </Zoom>
        </Grid>
        <Grid className="test" item md={6}>
          <Zoom right>
            <form ref={form} onSubmit={sendEmail}>
              <TextField
                type="text"
                name="name"
                required
                id="outlined-basic"
                label="name"
                variant="outlined"
              />
              <TextField
                name="email"
                required
                id="outlined-basic"
                label="email"
                variant="outlined"
              />

              <TextField
                multiline
                rows={4}
                name="text-box"
                required
                id="outlined-basic"
                label="message"
                variant="outlined"
              />
              <Button className="btncolor" type="submit" endIcon={<SendIcon />}>
                Send
              </Button>
            </form>
          </Zoom>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ContactSmaller;

const Wrapper = styled.div`
  max-width: 850px;
  padding: 2.5rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 984px) {
    display: none;
  }

  @media (max-width: 604px) {
    margin-top: 4rem;
  }
  @media (max-width: 300px) {
    margin-top: 6rem;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 400px;
    gap: 12px;
  }

  .test {
    width: 100%;
  }

  .btncolor {
    background: #0f6f6b;
    color: white;
  }
  .btncolor:hover {
    background: #a3e2df;
  }
`;
