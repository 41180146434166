import React from "react";
import styled from "styled-components";

export const Item3 = () => {
  return (
    <Wrapper>
      Currently working on a MERN eCommerce website with Brad Traversy course.
      Half way done.
    </Wrapper>
  );
};

const Wrapper = styled.section`
  text-align: center;
`;
