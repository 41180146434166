import React, { useState } from "react";
import styled from "styled-components";
import { SectionTitle } from "../../common/SectionTitle";

import { Item1 } from "./Item1";
import { Item2 } from "./Item2";
import { Item3 } from "./Item3";

function ProjectNav({ title }) {
  const links = [
    { id: 1, name: "FrontEnd" },
    { id: 2, name: "API" },
    { id: 3, name: "MERN" },
  ];

  const [activeLink, changeActiveLink] = useState(1);

  function toggleActive(id) {
    changeActiveLink(id);
  }

  function toggleActiveStyles(id) {
    if (id === activeLink) {
      return "active";
    } else {
      return "inactive";
    }
  }

  return (
    <Wrapper id="projects">
      <SectionTitle title="Projects" />
      <div className="nav-container">
        {links.map((element, index) => (
          <span
            key={element.id}
            className={toggleActiveStyles(element.id)}
            onClick={() => {
              toggleActive(element.id);
            }}
          >
            <span className="clickable">{links[index].name}</span>
          </span>
        ))}
      </div>
      {activeLink === 1 && <Item1 />}
      {activeLink === 2 && <Item2 />}
      {activeLink === 3 && <Item3 />}
    </Wrapper>
  );
}

export default ProjectNav;

const Wrapper = styled.section`
  .nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid #ebeef0;
    margin-bottom: 4rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .inactive {
    font-size: 25px;
    line-height: 25.7143px;
    font-weight: 500;
    text-decoration: none;
    color: #717171;
    transition: 0.4s;
  }

  .inactive:hover {
    color: #0d0c22;
    transition: 0.4s;
  }

  .active {
    position: relative;
    font-size: 25px;
    line-height: 25.7143px;
    font-weight: 500;
    text-decoration: none;
    color: #3abd90;
  }

  .active:after {
    position: absolute;
    content: "";
    display: block;
    background-color: #17b17d;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: -11px;
    border-radius: 8px 8px 0 0;
    left: 0;
  }

  .clickable {
    padding: 0px 24px 12px;
    cursor: pointer;
  }
`;
