import React from "react";
import styled from "styled-components";

export const Footer = () => {
  return <Wrapper>© 2022 Karan Singh. All rights reserved.</Wrapper>;
};

const Wrapper = styled.section`
  text-align: center;
  padding: 1em;
`;
